import { render, staticRenderFns } from "./dealcard-v1.vue?vue&type=template&id=c087e7b2&scoped=true&"
import script from "./dealcard-v1.vue?vue&type=script&lang=js&"
export * from "./dealcard-v1.vue?vue&type=script&lang=js&"
import style0 from "./dealcard-v1.vue?vue&type=style&index=0&id=c087e7b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c087e7b2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/milan/WebstormProjects/staging.weekend.rosian.org/weekend-travel-deals-webapp/webapp/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {VCard,VCardTitle,VDivider,VFlex,VIcon,VImg,VLayout,VList,VListTile,VListTileAvatar,VListTileContent,VSpacer})
